import {
  ReactNode,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { DbResultOk, supabase } from "../supabaseClient";
import { logError } from "../utils/SentryUtils";
import { CheckAccessClient } from "api-sdk";
import useAuth from "../hooks/useAuth";
import useCurrentProject from "hooks/useCurrentProject";
import { useCurrentProjectPage } from "./CurrentFrameProjectPage/CurrentFrameProjectPage";

export const fetchLastScanQuery = (projectId: string) =>
  supabase
    .from("scans")
    .select(
      `
      *,
      pages:page_scans(
        *
      )
    `
    )
    .eq("project_id", projectId)
    .order("created_at", { ascending: false })
    .limit(1)
    .single();

export type Scan = DbResultOk<ReturnType<typeof fetchLastScanQuery>>;
export type PageScan = Scan["pages"][number];

type ScanerContextProps = {
  startScan: (pageIds: string[]) => Promise<void>;
  currentPageScan: PageScan | null;
  isScaning: boolean;
  currentFullScan: Scan | null;
};

const ScanerContext = createContext<ScanerContextProps | null>(null);

const ScanerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const currentProject = useCurrentProject();
  const currentProjectPage = useCurrentProjectPage();

  const [currentFullScan, setCurrentFullScan] = useState<Scan | null>(null);

  const { session } = useAuth();
  const [isScaning, setIsScaning] = useState<boolean>(false);

  const client = useMemo(
    () =>
      new CheckAccessClient({
        environment: process.env.REACT_APP_CHECK_ACCESS_API_URL!,
        apiKey: `Bearer ${session.access_token}`,
      }),
    [session]
  );

  const fetchLastScan = useCallback(async () => {
    const { data, error } = await fetchLastScanQuery(currentProject.id);

    setCurrentFullScan(data);

    if (error && error.code !== "PGRST116") {
      logError("Error while fetching last scan", error);
    }
  }, [currentProject.id]);

  const startScan = useCallback(
    async (pageIds: string[], createNewFullScan?: boolean) => {
      if (isScaning) return;

      setIsScaning(true);

      const res = await client
        .postScan(
          {
            projectId: currentProject.id,
            scanId: createNewFullScan ? undefined : currentFullScan?.id,
            pageIds,
          },
          {
            maxRetries: 0,
          }
        )
        .catch((error) => {
          logError("Error while starting scan", error);
          return null;
        });

      setIsScaning(false);

      if (!res) return;

      await fetchLastScan();
    },
    [client, currentFullScan?.id, currentProject.id, fetchLastScan, isScaning]
  );

  useEffect(() => {
    fetchLastScan();
  }, [fetchLastScan]);

  const currentPageScan: PageScan | null = useMemo(() => {
    if (!currentFullScan) return null;

    return (
      currentFullScan.pages.find(
        (pageScan) => pageScan.page_id === currentProjectPage.id
      ) || null
    );
  }, [currentFullScan, currentProjectPage.id]);

  return (
    <ScanerContext.Provider
      value={{
        startScan,
        isScaning,
        currentPageScan,
        currentFullScan,
      }}
    >
      {children}
    </ScanerContext.Provider>
  );
};

export { ScanerContext, ScanerProvider };
