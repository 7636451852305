import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useTreeViewApiRef } from "@mui/x-tree-view/hooks/useTreeViewApiRef";
import { ReportContext } from "providers/Report";
import { useInterventions } from "providers/InterventionsProvider";
import { InterventionRequirement, NonCompliantError } from "@ca/report";
import { RichTreeViewApiRef } from "@mui/x-tree-view/RichTreeView/RichTreeView.types";

const useHeadingsInformationsMaps = () => {
  const { pageInterventions } = useInterventions();
  const { allErrors, pageReport } = useContext(ReportContext)!;

  const hierarchyErrorsMap = useMemo(
    () =>
      new Map(
        allErrors
          .filter((e) => e.type === "notRelevantHeadingHierarchy")
          .map((e) => [e.x_path, e] as const)
      ),
    [allErrors]
  );

  const contentErrorsMap = useMemo(
    () =>
      new Map(
        allErrors
          .filter((e) => e.type === "notRelevantHeadingText")
          .map((e) => [e.x_path, e] as const)
      ),
    [allErrors]
  );

  const missingTitlesInterventions = useMemo(() => {
    return new Set(pageInterventions.informationStructuring.missingTitles);
  }, [pageInterventions.informationStructuring.missingTitles]);

  const textRelevanceInterventionsRequirmentsMap = useMemo(() => {
    if (!pageReport)
      return new Map<string, InterventionRequirement.headingTextRelevance>();

    const interventions = pageReport.neededInterventions
      .filter((ni) => ni.type === "headingTextRelevance")
      .map((it) => [it.x_path, it] as const);

    return new Map(interventions);
  }, [pageReport]);

  return {
    hierarchyErrorsMap,
    contentErrorsMap,
    textRelevanceInterventionsRequirmentsMap,
    missingTitlesInterventions,
  };
};

export interface HeadingsRetributionContextProps {
  treeViewApiRef: RichTreeViewApiRef;

  contentErrorsMap: Map<string, NonCompliantError.notRelevantHeadingText>;
  hierarchyErrorsMap: Map<
    string,
    NonCompliantError.notRelevantHeadingHierarchy
  >;

  missingTitlesInterventions: Set<string>;

  textRelevanceInterventionsRequirmentsMap: Map<
    string,
    InterventionRequirement.headingTextRelevance
  >;

  isMissingTitlesEditMode: boolean;
  setIsMissingTitlesEditMode: React.Dispatch<React.SetStateAction<boolean>>;

  setMissingTitleIntervention: (xPath: string, isMissing: boolean) => void;
}

const HeadingsRetributionContext =
  createContext<HeadingsRetributionContextProps | null>(null);

const HeadingsRetributionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const treeViewApiRef = useTreeViewApiRef();
  const { updateInfos } = useInterventions();

  const [isMissingTitlesEditMode, setIsMissingTitlesEditMode] = useState(false);

  const {
    contentErrorsMap,
    hierarchyErrorsMap,
    textRelevanceInterventionsRequirmentsMap,
    missingTitlesInterventions,
  } = useHeadingsInformationsMaps();

  const setMissingTitleIntervention = useCallback(
    (xPath: string, isMissing: boolean) => {
      updateInfos((infos) => {
        const missingTitles = infos.informationStructuring.missingTitles.filter(
          (x) => x !== xPath
        );

        if (isMissing) {
          missingTitles.push(xPath);
        }

        infos.informationStructuring.missingTitles = missingTitles;
        return infos;
      });
    },
    [updateInfos]
  );

  return (
    <HeadingsRetributionContext.Provider
      value={{
        treeViewApiRef,

        contentErrorsMap,
        hierarchyErrorsMap,

        textRelevanceInterventionsRequirmentsMap,

        isMissingTitlesEditMode,
        setIsMissingTitlesEditMode,

        missingTitlesInterventions,

        setMissingTitleIntervention,
      }}
    >
      {children}
    </HeadingsRetributionContext.Provider>
  );
};

const useHeadingsRetribution = () => {
  const context = useContext(HeadingsRetributionContext);

  if (!context) {
    throw new Error(
      "useHeadingsRetribution must be used within a HeadingsRetributionProvider"
    );
  }

  return context;
};

export {
  HeadingsRetributionProvider,
  HeadingsRetributionContext,
  useHeadingsRetribution,
};
