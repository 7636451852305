import React, { useCallback, useMemo } from "react";
import { Typography } from "@mui/material";
import DocumentationDetectionStep from "../DocumentationDetectionStep";
import { NewProjectPayload } from "hooks/useCreateOrganization";
import { StepsModal } from "../StepsModal";
import useProjectStep from "../stepsHooks/useProjectStep";
import useCreateProject from "hooks/useCreateProject";
import useCurrentOrganization from "hooks/useCurrentOrganization";

interface AddProjectFormProps {
  onClose: () => void;
}

const AddProjectForm: React.FC<AddProjectFormProps> = ({ onClose }) => {
  const organization = useCurrentOrganization();
  const createProject = useCreateProject();

  const handleSubmit = useCallback(
    async (projectPayload: NewProjectPayload) => {
      if (!projectPayload.url) return null;

      await createProject({
        organizationId: organization.id,
        name: projectPayload.name,
        url: projectPayload.url,
      });

      return null;
    },
    [createProject, organization.id]
  );

  const { step: projectStep } = useProjectStep({ handleSubmit });

  const warning: StepsModal.Step = useMemo(
    () => ({
      label: "Attention!",
      StepComponent: (
        <Typography className="flex flex-col flex-grow justify-center items-center">
          <label className="text-xl underline font-bold" htmlFor="projectName">
            Attention : si vous décidez d'ajouter un projet, celui-ci sera
            automatiquement inclus dans votre abonnement, ce qui entraînera des
            frais supplémentaires.
          </label>
        </Typography>
      ),
    }),
    []
  );

  const documentationStep: StepsModal.Step = useMemo(
    () => ({
      label: "Documentation",
      StepComponent: <DocumentationDetectionStep detectedCms={null} />,
      canGoBack: false,
    }),
    []
  );

  const endStep: StepsModal.Step = useMemo(
    () => ({
      label: "Fin",
      StepComponent: (
        <Typography variant="h6">Formulaire complété !</Typography>
      ),
    }),
    []
  );

  return (
    <StepsModal.Form
      steps={[warning, projectStep, documentationStep, endStep]}
      onClose={onClose}
    />
  );
};

export default AddProjectForm;
