import {
  guess_interventions,
  Infos,
  Scan,
  wasm_check_or_migrate_infos_file,
} from "@ca/report";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { OrganizationsContext } from "./OrganizationsProvider";
import { useCurrentProjectPage } from "./CurrentFrameProjectPage/CurrentFrameProjectPage";
import useScaner from "hooks/useScaner";

type InterventionsContextProps = {
  pageInterventions: Infos;
  updateInfos: (update: (infos: Infos) => Infos) => void;
};

const InterventionsContext = createContext<InterventionsContextProps | null>(
  null
);

const prepareInterventionsFile = (infos: Infos, scan: Scan | null): Infos => {
  const checkedInfos = wasm_check_or_migrate_infos_file(infos);

  if (scan) {
    const guessedInfos = guess_interventions(checkedInfos, scan);
    if (guessedInfos !== undefined) {
      return guessedInfos;
    }
  }

  return checkedInfos;
};

const InterventionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const currentProjectPage = useCurrentProjectPage();
  const { updateProjectPage } = useContext(OrganizationsContext)!;
  const { currentPageScan } = useScaner();

  // We duplicate SSOT here to escape update interventions ping
  const [pageInterventions, setPageInterventions] = useState<Infos>(
    prepareInterventionsFile(
      currentProjectPage.infos,
      currentPageScan?.scan || null
    )
  );

  useEffect(() => {
    setPageInterventions(() =>
      prepareInterventionsFile(
        currentProjectPage.infos,
        currentPageScan?.scan || null
      )
    );
  }, [currentPageScan?.scan, currentProjectPage.infos]);

  const updateInfos = useCallback(
    (update: (infos: Infos) => Infos) => {
      setPageInterventions((prev) => {
        const newInfos = { ...update(prev) };

        updateProjectPage({
          project_id: currentProjectPage.project_id,
          id: currentProjectPage.id,
          infos: newInfos,
        });

        return newInfos;
      });
    },
    [currentProjectPage.id, currentProjectPage.project_id, updateProjectPage]
  );

  return (
    <InterventionsContext.Provider
      value={{
        pageInterventions,
        updateInfos,
      }}
    >
      {children}
    </InterventionsContext.Provider>
  );
};

export const useInterventions = () => {
  return useContext(InterventionsContext)!;
};

export { InterventionsContext, InterventionProvider };
