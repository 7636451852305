import React from "react";
import Router from "./router";
import AuthProvider from "./providers/AuthProvider";
import { OrganizationProvider } from "./providers/OrganizationsProvider";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ConfettiProvider from "providers/ConfettiProvider";
import * as Sentry from "@sentry/react";
import ErrorFallback from "components/ErrorFallback";

let theme = createTheme({
  palette: {
    primary: {
      main: "#325B4E",
      light: "#325B4E",
      dark: "#5da88d",
    },
    secondary: {
      main: "#5eae92",
      light: "#5eae92",
      dark: "#89e8c6",
    },
    common: {
      black: "#0C1F19",
      white: "#FEFCF4",
    },
  },
});
theme = responsiveFontSizes(theme);

// This is to bypass SameOrigin policy for the iframe
//
// "you need to set document.domain to the same value in both the parent domain and the subdomain.
// This is necessary even if doing so is setting the parent domain back to its original value."
// https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy#changing_origin
//
// eslint-disable-next-line no-self-assign
document.domain = document.domain;
console.log("Document origin set to:", document.domain);

function App() {
  const errorFallback: Sentry.FallbackRender = ({ error }) => (
    <ErrorFallback error={error} />
  );

  return (
    <div className="h-screen w-screen">
      <Sentry.ErrorBoundary fallback={errorFallback}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <AuthProvider>
              <OrganizationProvider>
                <ConfettiProvider>
                  <Router />
                </ConfettiProvider>
              </OrganizationProvider>
            </AuthProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </Sentry.ErrorBoundary>
    </div>
  );
}

export default App;
