import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box/Box";
import React, { useCallback, useContext } from "react";
import {
  getCriteriaState,
  NonCompliantCriteria,
  ReportContext,
  TargetedNonCompliantErrors,
} from "providers/Report";
import { ReactComponent as TextIcon } from "../../../../assets/icons/icon-text.svg";
import SidebarTopicHeader from "./SidebarTopicHeader";
import CriteriaAccordion from "../accordion/CriteriaAccordion";
import { CRITERIA_STATE_DETAILS } from "definitions/criteria_state_details";
import RetributionIcon from "components/check-access/scanReportRetribution/RetributionIcon";
import { useInterventions } from "providers/InterventionsProvider";
import useScaner from "hooks/useScaner";
import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import Radio from "@mui/material/Radio/Radio";
import { XPathFinderContext } from "providers/XPathFinder";
import { toLangDisplayName } from "utils/isoLangFrMap";

const MandatoryElementCriteria: React.FC<{
  criteriaNumber: number;
  title: string;
  children?: React.ReactNode;
}> = ({ criteriaNumber, title, children }) => {
  const { pageReport } = useContext(ReportContext)!;

  const { palette } = useTheme();
  const criteria = pageReport?.criterias.find(
    (criteria) =>
      criteria.topic === "mandatoryElements" &&
      criteria.number === criteriaNumber
  );

  if (!criteria) return null;
  const criteriaState = getCriteriaState(criteria);
  const details = CRITERIA_STATE_DETAILS[criteriaState];

  return (
    <CriteriaAccordion
      title={title}
      Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
      type={details.paletteTheme}
      children={children}
    />
  );
};

const TitleRelevantCriteria: React.FC = () => {
  const { palette } = useTheme();
  const { pageInterventions, updateInfos } = useInterventions();
  const { pageReport } = useContext(ReportContext)!;
  const { currentPageScan } = useScaner();

  const updatePageTitleRelevanceIntervention = useCallback(
    (hasGoodTitle: boolean) => {
      if (!currentPageScan) return;

      updateInfos((infos) => {
        const pageTitleRelevance = new Map(infos.pageTitleRelevance);
        pageTitleRelevance.set(currentPageScan.scan.global.title, hasGoodTitle);
        infos.pageTitleRelevance = pageTitleRelevance;

        return infos;
      });
    },
    [currentPageScan, updateInfos]
  );

  if (!currentPageScan) return;

  const criteria = pageReport?.criterias.find(
    (criteria) =>
      criteria.topic === "mandatoryElements" && criteria.number === 6
  );

  if (!criteria) return null;

  const criteriaState = getCriteriaState(criteria);
  const details = CRITERIA_STATE_DETAILS[criteriaState];

  const hasGoodTitle = pageInterventions.pageTitleRelevance.get(
    currentPageScan.scan.global.title
  );

  let interventionFormValue = "";
  if (hasGoodTitle) {
    interventionFormValue = "relevant";
  } else if (hasGoodTitle !== undefined && !hasGoodTitle) {
    interventionFormValue = "notRelevant";
  }

  return (
    <CriteriaAccordion
      title={"8.6 - Titre de la page pertinent"}
      Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
      type={details.paletteTheme}
      defaultIsExpanded={true}
    >
      <Typography variant="body1" sx={{ marginBottom: 2 }}>
        Le titre de la page est{" "}
        <strong>"{currentPageScan.scan.global.title}"</strong> est-il pertinent
        ?
      </Typography>
      <FormControl>
        <FormLabel>Pertinence</FormLabel>
        <RadioGroup
          row
          name="pageTitleRelevantRadioGroup"
          value={interventionFormValue}
        >
          <FormControlLabel
            value="relevant"
            control={
              <Radio
                name="pageTitleRelevantRadioGroupRelevant"
                color={details.paletteTheme}
                onClick={() => updatePageTitleRelevanceIntervention(true)}
              />
            }
            label="Pertinent"
          />
          <FormControlLabel
            value="notRelevant"
            control={
              <Radio
                color={details.paletteTheme}
                name="pageTitleRelevantRadioGroupNotRelevant"
                onClick={() => updatePageTitleRelevanceIntervention(false)}
              />
            }
            label="Non pertinent"
          />
        </RadioGroup>
      </FormControl>
    </CriteriaAccordion>
  );
};

const LangRelevanceCriteria: React.FC = () => {
  const { pageReport } = useContext(ReportContext)!;
  const { currentPageScan } = useScaner();
  const { palette } = useTheme();

  const criteria = pageReport?.criterias.find(
    (criteria) =>
      criteria.topic === "mandatoryElements" && criteria.number === 4
  );

  if (!criteria) return null;

  const criteriaState = getCriteriaState(criteria);
  const details = CRITERIA_STATE_DETAILS[criteriaState];

  if (!currentPageScan) return null;

  let error = null;
  if (criteriaState === "nonCompliant") {
    const criteriaError = (criteria as NonCompliantCriteria).state
      .nonCompliant[0];
    error = criteriaError.type;
  }

  const defaultLang = currentPageScan.scan.global.lang
    ? toLangDisplayName(currentPageScan.scan.global.lang)
    : "";
  const detectedLang = currentPageScan.scan.texts.mainLangDetected
    ? toLangDisplayName(currentPageScan.scan.texts.mainLangDetected)
    : "";

  return (
    <CriteriaAccordion
      title={"8.4 - Langue de la page pertinente"}
      Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
      type={details.paletteTheme}
    >
      {error === "missingDefaultLang" ? (
        <Typography variant="body1">
          La langue par défaut n'est pas défini
        </Typography>
      ) : error === "invalidFormatForDefaultLang" ? (
        <Typography variant="body1">
          La langue par défaut est défini à <strong>{defaultLang}</strong> ce
          n'est pas un format valide selon la norme{" "}
          <a href="https://developer.mozilla.org/fr/docs/Web/HTML/Global_attributes/lang">
            BCP47
          </a>
        </Typography>
      ) : error === "defaultLangDoesNotMatchDetectedLang" ? (
        <Typography variant="body1">
          La langue par défaut est défini à <strong>{defaultLang}</strong> mais
          nous avons identifié que le texte du site était écris en{" "}
          <strong>{detectedLang}</strong>{" "}
        </Typography>
      ) : (
        <Typography variant="body1">
          La langue par défaut et la langue détectée globalement sur les textes
          de la page sont toutes les deux en <strong>{defaultLang}</strong>
        </Typography>
      )}
    </CriteriaAccordion>
  );
};

const ChangedLanguageCriteria: React.FC<{
  title: string;
  criteriaNumber: number;
}> = ({ title, criteriaNumber }) => {
  const { pageReport } = useContext(ReportContext)!;
  const { scrollPictoIntoView } = useContext(XPathFinderContext)!;

  const { palette } = useTheme();

  const criteria = pageReport?.criterias.find(
    (criteria) =>
      criteria.topic === "mandatoryElements" &&
      criteria.number === criteriaNumber
  );

  if (!criteria) return null;

  const criteriaState = getCriteriaState(criteria);

  const details = CRITERIA_STATE_DETAILS[criteriaState];

  const errors =
    criteriaState === "nonCompliant"
      ? (criteria as NonCompliantCriteria).state.nonCompliant
      : [];

  const isMissingLangError =
    errors.length === 1 && errors[0].type === "missingDefaultLang";

  return (
    <CriteriaAccordion
      title={title}
      Icon={<details.Icon stroke={palette[details.paletteTheme].main} />}
      type={details.paletteTheme}
    >
      {!isMissingLangError && errors.length > 0 && (
        <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
          {errors.map((error, index) => (
            <RetributionIcon
              onClick={() =>
                scrollPictoIntoView(
                  "textMandatoryElement",
                  (error as TargetedNonCompliantErrors).x_path
                )
              }
              Icon={(props) => (
                <TextIcon {...props} fill={palette.common.white} />
              )}
              key={index}
              color={palette[details.paletteTheme].main}
            />
          ))}
        </Box>
      )}
    </CriteriaAccordion>
  );
};

export interface MandatoryElementsSidebarTopicDetailsProps {}

const MandatoryElementsSidebarTopicDetails: React.FC<
  MandatoryElementsSidebarTopicDetailsProps
> = () => {
  const { currentPageScan } = useScaner();
  if (!currentPageScan) return null;

  return (
    <Box sx={{ width: "100%" }}>
      <SidebarTopicHeader topic="mandatoryElements" titleFontSize="1.5rem" />
      <Box
        sx={{
          margin: "25px 8px",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <Box>
          <MandatoryElementCriteria
            criteriaNumber={1}
            title="8.1 - Doctype définie"
          >
            <Typography variant="body1">
              Le doctype de la page est "
              <strong>{currentPageScan.scan.global.doctype}</strong>"
            </Typography>
          </MandatoryElementCriteria>

          <MandatoryElementCriteria
            criteriaNumber={3}
            title="8.3 - Langue par défaut défini"
          >
            {currentPageScan.scan.global.lang ? (
              <Typography variant="body1">
                La langue par défaut est défini à{" "}
                <strong>
                  {toLangDisplayName(currentPageScan.scan.global.lang)}
                </strong>
              </Typography>
            ) : (
              <Typography variant="body1">
                La langue par défaut n'est pas défini
              </Typography>
            )}
          </MandatoryElementCriteria>

          <LangRelevanceCriteria />

          <MandatoryElementCriteria
            criteriaNumber={5}
            title="8.5 - Titre de la page défini"
          >
            <Typography variant="body1">
              Le titre de la page est{" "}
              <strong>"{currentPageScan.scan.global.title}"</strong>
            </Typography>
          </MandatoryElementCriteria>

          <TitleRelevantCriteria />

          <ChangedLanguageCriteria
            title="8.7 - Changement de langue indiqué"
            criteriaNumber={7}
          />

          <ChangedLanguageCriteria
            title="8.8 - Changement de langue pertinent"
            criteriaNumber={8}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default MandatoryElementsSidebarTopicDetails;
