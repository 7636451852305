import React, { useCallback, useMemo, useState } from "react";
import { Typography, Input } from "@mui/material";
import DocumentationDetectionStep from "../DocumentationDetectionStep";
import useCreateOrganization, {
  NewProjectPayload,
} from "hooks/useCreateOrganization";
import { StepsModal } from "../StepsModal";
import useProjectStep from "../stepsHooks/useProjectStep";

interface AddOrganizationFormProps {
  onClose: () => void;
}

const AddOrganizationForm: React.FC<AddOrganizationFormProps> = ({
  onClose,
}) => {
  const createOrganization = useCreateOrganization();

  const [organisationName, setOrganisationName] = useState("");

  const handleSubmit = useCallback(
    async (projectPayload: NewProjectPayload) => {
      if (!projectPayload.url) return null;

      await createOrganization({
        organization: {
          name: organisationName,
        },
        projectPayload,
      });

      return null;
    },
    [createOrganization, organisationName]
  );

  const { step: projectStep } = useProjectStep({ handleSubmit });

  const warning: StepsModal.Step = useMemo(
    () => ({
      label: "Attention!",
      StepComponent: (
        <Typography className="flex flex-col flex-grow justify-center items-center">
          <label className="text-xl underline font-bold" htmlFor="projectName">
            Attention : si vous décidez d'ajouter un projet, celui-ci sera
            automatiquement inclus dans votre abonnement, ce qui entraînera des
            frais supplémentaires.
          </label>
        </Typography>
      ),
    }),
    []
  );

  const organisationStep: StepsModal.Step = useMemo(
    () => ({
      label: "Organisation",
      validateStep: async () => {
        if (organisationName.trim().length === 0) {
          return "Le nom de l'organisation est requis";
        }
        return null;
      },
      StepComponent: (
        <Typography className="flex flex-col">
          <label htmlFor="organizationName">
            Quel est le nom de votre entreprise?
          </label>
          <Input
            id="organizationName"
            name="organizationName"
            value={organisationName}
            onChange={(e) => setOrganisationName(e.target.value)}
          />
        </Typography>
      ),
    }),
    [organisationName]
  );

  const documentationStep: StepsModal.Step = useMemo(
    () => ({
      label: "Documentation",
      StepComponent: <DocumentationDetectionStep detectedCms={null} />,
      canGoBack: false,
    }),
    []
  );

  const endStep: StepsModal.Step = useMemo(
    () => ({
      label: "Fin",
      StepComponent: (
        <Typography variant="h6">Formulaire complété !</Typography>
      ),
    }),
    []
  );

  return (
    <StepsModal.Form
      steps={[
        warning,
        organisationStep,
        projectStep,
        documentationStep,
        endStep,
      ]}
      onClose={onClose}
    />
  );
};

export default AddOrganizationForm;
