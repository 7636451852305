import React from "react";
import Box from "@mui/material/Box/Box";
import useScaner from "hooks/useScaner";
import { useInterventions } from "providers/InterventionsProvider";
import ButtonGroup from "@mui/material/ButtonGroup/ButtonGroup";
import Typography from "@mui/material/Typography/Typography";
import Button from "@mui/material/Button/Button";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import { construct_title_content_relevance_key } from "@ca/report";

export interface HeadingsDefnieAllTextInterventionsProps {}

const HeadingsDefnieAllTextInterventions: React.FC<
  HeadingsDefnieAllTextInterventionsProps
> = () => {
  const { currentPageScan } = useScaner();
  const { updateInfos } = useInterventions();

  const handleUpdateAllTextInterventions = (
    allRelevant: boolean | undefined
  ) => {
    if (!currentPageScan) return;

    const titlesScans = currentPageScan.scan.informationStructuring.titles;
    updateInfos((infos) => {
      const textsIt = new Map(infos.informationStructuring.titlesTextRelevance);

      if (allRelevant === undefined) {
        titlesScans.forEach((title) => {
          textsIt.delete(
            construct_title_content_relevance_key(title.xPath, title.text)
          );
        });
      } else {
        titlesScans.forEach((title) => {
          textsIt.set(
            construct_title_content_relevance_key(title.xPath, title.text),
            allRelevant
          );
        });
      }

      infos.informationStructuring.titlesTextRelevance = textsIt;
      return infos;
    });
  };

  return (
    <Box
      sx={{
        marginBottom: 2,
      }}
    >
      <Typography>Définir tous les textes des titres comme</Typography>
      <ButtonGroup
        color="warning"
        size={"small"}
        sx={{
          "& .MuiButton-root": {
            fontSize: "0.8rem",
            padding: "4px 10px",
          },
        }}
        variant="contained"
        aria-label="All titles text relevance button group"
      >
        <Button onClick={() => handleUpdateAllTextInterventions(undefined)}>
          <SettingsBackupRestoreIcon />
        </Button>
        <Button onClick={() => handleUpdateAllTextInterventions(true)}>
          Pertinent
        </Button>
        <Button onClick={() => handleUpdateAllTextInterventions(false)}>
          Non pertinent
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default HeadingsDefnieAllTextInterventions;
