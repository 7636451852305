import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { CheckAccessFrameContext } from "./CheckAccessFrame/CheckAccessFrame";
import { evaluateElementPath } from "utils/xPathUtils";

export interface XPathFinderContextProps {
  addXPath: (xPath: string) => void;
  removeXPath: (xPath: string) => void;

  getElementByXPath: (xPath: string) => HTMLElement | null;

  scrollPictoIntoView: (key: string, xPath: string) => void;
  scrollXPathIntoView: (xPath: string) => void;
}

const XPathFinderContext = createContext<XPathFinderContextProps | null>(null);

const XPathFinderProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { frame, shadowContainer } = useContext(CheckAccessFrameContext)!;

  const xPathCounter = useRef<Map<string, number>>(new Map());
  const [elements, setElements] = useState<Map<string, HTMLElement | null>>(
    new Map()
  );
  const [elementsVisibility, setElementsVisibility] = useState<
    Map<HTMLElement, boolean>
  >(new Map());

  const documentIntersectionObserver = useRef<IntersectionObserver>();

  useEffect(() => {
    if (!frame?.contentDocument) return;

    const documentObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          setElementsVisibility((prev) => {
            const newVisibility = new Map(prev);
            const target = entry.target as HTMLElement;
            const isVisible =
              entry.isIntersecting ||
              // Some element have a wrong 0x0 size detected (due to css), 'checkVisibility' is a fallback for that case
              (target.checkVisibility({
                contentVisibilityAuto: true,
                checkOpacity: true,
                checkVisibilityCSS: true,
              }) &&
                ((target.clientHeight > 5 && target.clientWidth > 5) ||
                  (target.offsetHeight > 5 && target.offsetWidth > 5)));

            newVisibility.set(target, isVisible);
            return newVisibility;
          });
        });
      },
      { root: frame.contentDocument.documentElement, threshold: 0.0 }
    );

    documentIntersectionObserver.current = documentObserver;

    return () => {
      documentObserver.disconnect();
    };
  }, [frame?.contentDocument]);

  const elementsRef = useRef(elements);
  useEffect(() => {
    elementsRef.current = elements;
  }, [elements]);

  const addXPath = useCallback(
    (xPath: string) => {
      setElements((prev) => {
        xPathCounter.current.set(
          xPath,
          (xPathCounter.current.get(xPath) || 0) + 1
        );

        if (prev.has(xPath)) return prev;

        const element = frame?.contentDocument
          ? evaluateElementPath(frame?.contentDocument, xPath)
          : null;

        if (element) {
          documentIntersectionObserver.current?.observe(element);
        }

        return new Map(prev.set(xPath, element));
      });
    },
    [frame?.contentDocument]
  );

  const removeXPath = useCallback((xPath: string) => {
    setElements((prev) => {
      const actualCount = xPathCounter.current.get(xPath);
      if (actualCount === undefined) {
        console.warn("XPath not found in counter", xPath);
        return prev;
      }
      if (actualCount > 1) {
        xPathCounter.current.set(xPath, actualCount - 1);
        return prev;
      }

      const element = prev.get(xPath);
      if (element) {
        documentIntersectionObserver.current?.unobserve(element);
      }

      xPathCounter.current.delete(xPath);
      prev.delete(xPath);

      return new Map(prev);
    });
  }, []);

  const getElementByXPath = useCallback(
    (xPath: string) => {
      const contentDocument = frame?.contentDocument;
      if (!contentDocument) return null;

      const element = elements.get(xPath);

      if (!element || !contentDocument?.body?.contains(element)) return null;
      if (elementsVisibility.get(element) === false) return null;

      return element;
    },
    [elements, elementsVisibility, frame?.contentDocument]
  );

  const retrievePicto = useCallback(
    (key: string, xPath: string) => {
      const el = shadowContainer.current?.querySelector(
        `[data-ca-retrival-id="${key}-${xPath}"]`
      );
      if (!el || el.nodeType !== Node.ELEMENT_NODE) {
        return null;
      }

      return el as HTMLElement;
    },
    [shadowContainer]
  );

  const scrollXPathIntoView = useCallback(
    (xPath: string) => {
      if (!frame?.contentWindow) return;

      const element = getElementByXPath(xPath);
      if (!element) return;

      element.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    },
    [frame, getElementByXPath]
  );

  const scrollPictoIntoView = useCallback(
    (key: string, xPath: string) => {
      scrollXPathIntoView(xPath);

      let retry = 0;
      let interval = setInterval(() => {
        const picto = retrievePicto(key, xPath);

        if (!picto && retry > 50) {
          console.warn(
            `Picto ${key}-${xPath} not found after ${retry} retries`
          );
          clearInterval(interval);
          return;
        }

        if (!picto) {
          retry++;
          return;
        }

        picto.click();
        clearInterval(interval);
      }, 70);
    },
    [retrievePicto, scrollXPathIntoView]
  );

  useEffect(() => {
    if (!frame?.contentDocument) return;

    const observer = new MutationObserver(() => {
      if (!frame?.contentDocument || !elementsRef.current) return;
      const contentDocument = frame.contentDocument;

      const elementsToAdd = [...elementsRef.current.entries()]
        .filter(([_, element]) => !element)
        .map(
          ([xPath, _]) =>
            [xPath, evaluateElementPath(contentDocument, xPath)] as const
        )
        .filter(([_, element]) => element !== null);

      const elementsToRemoveOrReplace = [...elementsRef.current.entries()]
        .filter(
          ([_, element]) =>
            element !== null && !contentDocument.body.contains(element)
        )
        .map(
          ([xPath, _]) =>
            [xPath, evaluateElementPath(contentDocument, xPath)] as const
        );

      const changesToMake = [...elementsToAdd, ...elementsToRemoveOrReplace];
      if (changesToMake.length === 0) return;

      setElements((prev) => {
        const newElements = new Map(prev);

        changesToMake.forEach(([xPath, element]) => {
          const prevElement = prev.get(xPath);
          if (prevElement) {
            documentIntersectionObserver.current?.unobserve(prevElement);
          }

          if (element) {
            documentIntersectionObserver.current?.observe(element);
          }

          newElements.set(xPath, element);
        });

        return newElements;
      });
    });

    observer.observe(frame.contentDocument, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [frame?.contentDocument]);

  return (
    <XPathFinderContext.Provider
      value={{
        addXPath,
        removeXPath,
        getElementByXPath,

        scrollPictoIntoView,
        scrollXPathIntoView,
      }}
    >
      {children}
    </XPathFinderContext.Provider>
  );
};

const useXPathFinder = (xPath: string) => {
  const { addXPath, removeXPath, getElementByXPath } =
    useContext(XPathFinderContext)!;

  useEffect(() => {
    addXPath(xPath);

    return () => removeXPath(xPath);
  }, [xPath, addXPath, removeXPath]);

  return getElementByXPath(xPath);
};

export { XPathFinderContext, XPathFinderProvider, useXPathFinder };
