import React, { useCallback, useContext, useMemo } from "react";
import { useInterventions } from "providers/InterventionsProvider";
import { ReportContext } from "providers/Report";
import Box from "@mui/material/Box/Box";
import useScaner from "hooks/useScaner";
import FrameTitleIntervention from "../interventions/frames/FrameTitleIntervention";
import RetributionButtonPopper from "../RetributionButtonPopper";
import { FrameScan } from "@ca/report";

export interface FramesRetributionProps {}

const FramesRetribution: React.FC<FramesRetributionProps> = () => {
  const { currentPageScan } = useScaner();

  const { pageInterventions, updateInfos } = useInterventions();
  const { pageReport } = useContext(ReportContext)!;

  const handleUpdateIntervention = useCallback(
    (scan: FrameScan, hasGoodTitle: boolean) => {
      updateInfos((infos) => {
        const frameHasGoodTitle = new Map(infos.frameHasGoodTitle);
        frameHasGoodTitle.set(scan.xPath, hasGoodTitle);

        return { ...infos, frameHasGoodTitle };
      });
    },
    [updateInfos]
  );

  const isElementTooSmall = useCallback((el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    return rect.width < 80 || rect.height < 80;
  }, []);

  const framesRetribution = useMemo(() => {
    if (!currentPageScan) return [];

    return currentPageScan.scan.frames.map((frameScan) => {
      const needIntervention =
        pageReport?.neededInterventions.some(
          (intervention) =>
            "xPath" in intervention &&
            intervention.xPath === frameScan.xPath &&
            intervention.type === "frameTitle"
        ) || false;

      return (
        <RetributionButtonPopper
          key={frameScan.xPath}
          xPath={frameScan.xPath}
          placement={(el) =>
            isElementTooSmall(el) ? "bottom" : "bottom-start"
          }
          offset={(el) => (isElementTooSmall(el) ? [0, 4] : [4, -36])}
        >
          <Box
            sx={{
              display: "flex",
              gap: "4px",
            }}
          >
            <FrameTitleIntervention
              frameScan={frameScan}
              needIntervention={needIntervention}
              hasGoodTitleIntervention={pageInterventions.frameHasGoodTitle.get(
                frameScan.xPath
              )}
              onUpdateIntervention={(it) =>
                handleUpdateIntervention(frameScan, it)
              }
            />
          </Box>
        </RetributionButtonPopper>
      );
    });
  }, [
    currentPageScan,
    pageReport?.neededInterventions,
    pageInterventions.frameHasGoodTitle,
    isElementTooSmall,
    handleUpdateIntervention,
  ]);

  return <React.Fragment>{framesRetribution}</React.Fragment>;
};

export default FramesRetribution;
