import useScaner from "hooks/useScaner";
import { CheckAccessFrameContext } from "providers/CheckAccessFrame/CheckAccessFrame";
import { ReportContext } from "providers/Report";
import React, { useCallback, useContext, useMemo } from "react";
import RetributionButton from "../RetributionButton";
import { ReactComponent as TextIcon } from "../../../../assets/icons/icon-text.svg";
import Box from "@mui/material/Box/Box";
import Typography from "@mui/material/Typography/Typography";
import { useInterventions } from "providers/InterventionsProvider";
import { construct_text_lang_relevance_key, TextScan } from "@ca/report";
import FormControl from "@mui/material/FormControl/FormControl";
import FormLabel from "@mui/material/FormLabel/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import Radio from "@mui/material/Radio/Radio";
import RetributionButtonPopper from "../RetributionButtonPopper";
import { toLangDisplayName } from "utils/isoLangFrMap";

export interface MandatoryElementsRetributionProps {}

const MandatoryElementsRetribution: React.FC<
  MandatoryElementsRetributionProps
> = () => {
  const { currentPageScan } = useScaner();
  const { allTargetedErrors } = useContext(ReportContext)!;
  const { contentDocument } = useContext(CheckAccessFrameContext)!;
  const { pageInterventions, updateInfos } = useInterventions();

  const updateTextLangIntervention = useCallback(
    (textScan: TextScan, isLangRelevant: boolean) => {
      updateInfos((infos) => {
        const textLangRelevance = new Map(infos.textLangRelevance);
        if (!textScan.nearestLang) {
          console.warn(
            "Trying to update text lang relevance without nearest lang"
          );
        }

        const key = construct_text_lang_relevance_key(
          textScan.content,
          textScan.nearestLang || ""
        );

        textLangRelevance.set(key, isLangRelevant);

        return {
          ...infos,
          textLangRelevance,
        };
      });
    },
    [updateInfos]
  );

  const allTexts = useMemo(() => {
    if (!contentDocument || !currentPageScan) return [];

    return currentPageScan.scan.texts.textScans.map((text) => {
      const isLangIndicatedError = allTargetedErrors.find(
        (err) =>
          err.type === "languageChangeNotSpecified" && err.x_path === text.xPath
      );
      const isLangRelevantError = allTargetedErrors.find(
        (err) =>
          err.type === "languageChangeNotRelevant" && err.x_path === text.xPath
      );

      const key = construct_text_lang_relevance_key(
        text.content,
        text.nearestLang || ""
      );

      const langRelevantIntervention = text.nearestLang
        ? pageInterventions.textLangRelevance.get(key)
        : undefined;

      const interventionFormValue =
        langRelevantIntervention === undefined
          ? ""
          : langRelevantIntervention
            ? "relevant"
            : "notRelevant";

      const stateType =
        isLangIndicatedError || isLangRelevantError ? "error" : "info";

      return (
        <RetributionButtonPopper
          xPath={text.xPath}
          key={text.xPath}
          placement={"left"}
          offset={[0, 4]}
        >
          <Box>
            <RetributionButton
              retrivalId={`textMandatoryElement-${text.xPath}`}
              Icon={(props) => (
                <TextIcon {...props} fill="white" stroke="white" />
              )}
              xPath={text.xPath}
              tooltipProps={{
                title: "Element obligatoire",
                content: (
                  <Box>
                    <Typography
                      sx={{
                        marginBottom: "5px",
                        lineHeight: "1",
                      }}
                    >
                      Langue du text par défaut:{" "}
                      <strong>
                        {text.nearestLang &&
                          toLangDisplayName(text.nearestLang)}
                      </strong>
                    </Typography>

                    <Typography
                      sx={{
                        marginBottom: "0px",
                        lineHeight: "1",
                      }}
                    >
                      Langue du text détecté:{" "}
                      <strong>
                        {text.langDetected &&
                          toLangDisplayName(text.langDetected)}
                      </strong>
                    </Typography>

                    <Typography
                      sx={{
                        marginTop: "20px",
                        marginBottom: "10px",
                        lineHeight: "1",
                      }}
                    >
                      La langue associée à se texte à été jugée{" "}
                      {isLangRelevantError ? "non " : ""}pertinente voulez vous
                      la changer ?
                    </Typography>
                    <FormControl>
                      <FormLabel>Pertinence</FormLabel>
                      <RadioGroup
                        row
                        name={`textLangRelevanceRadioGroup-${text.xPath}`}
                        value={interventionFormValue}
                      >
                        <FormControlLabel
                          value="relevant"
                          control={
                            <Radio
                              name={`textLangRelevanceRadioGroup-${text.xPath}-Relevant`}
                              color={stateType}
                              onClick={() =>
                                updateTextLangIntervention(text, true)
                              }
                            />
                          }
                          label="Pertinent"
                        />
                        <FormControlLabel
                          value="notRelevant"
                          control={
                            <Radio
                              name={`textLangRelevanceRadioGroup-${text.xPath}-NotRelevant`}
                              color={stateType}
                              onClick={() =>
                                updateTextLangIntervention(text, false)
                              }
                            />
                          }
                          label="Non pertinent"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                ),
                referenceLink:
                  "https://accessibilite.numerique.gouv.fr/methode/criteres-et-tests/#8.7",
              }}
              type={stateType}
            />
          </Box>
        </RetributionButtonPopper>
      );
    });
  }, [
    allTargetedErrors,
    contentDocument,
    pageInterventions.textLangRelevance,
    currentPageScan,
    updateTextLangIntervention,
  ]);

  return <React.Fragment>{allTexts}</React.Fragment>;
};

export default MandatoryElementsRetribution;
