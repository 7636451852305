import {
  construct_title_content_relevance_key,
  construct_title_hierarchy_relevance_key,
  TitleScan,
} from "@ca/report";
import FormControl from "@mui/material/FormControl/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import Typography from "@mui/material/Typography/Typography";
import { useInterventions } from "providers/InterventionsProvider";
import React from "react";

export interface HeadingOverlayFormProps {
  scan: TitleScan;
  color: "warning" | "error" | "info";
}

const HeadingOverlayForm: React.FC<HeadingOverlayFormProps> = ({
  scan,
  color,
}) => {
  const { pageInterventions, updateInfos } = useInterventions();

  const hasGoodHierarchy =
    pageInterventions.informationStructuring.titlesHierarchyRelevance.get(
      construct_title_hierarchy_relevance_key(scan.xPath, scan.level)
    );

  const hasGoodContent =
    pageInterventions.informationStructuring.titlesHierarchyRelevance.get(
      construct_title_content_relevance_key(scan.xPath, scan.text)
    );

  const handleUpdateHierarchyIntervention = (
    value: "relevant" | "notRelevant" | "auto"
  ) => {
    const key = construct_title_hierarchy_relevance_key(scan.xPath, scan.level);

    updateInfos((infos) => {
      if (value === "auto") {
        infos.informationStructuring.titlesHierarchyRelevance.delete(key);
      } else {
        infos.informationStructuring.titlesHierarchyRelevance.set(
          key,
          value === "relevant"
        );
      }

      infos.informationStructuring.titlesHierarchyRelevance = new Map(
        infos.informationStructuring.titlesHierarchyRelevance
      );

      return infos;
    });
  };

  const handleUpdateContentIntervention = (
    value: "relevant" | "notRelevant"
  ) => {
    const key = construct_title_content_relevance_key(scan.xPath, scan.text);
    updateInfos((infos) => {
      infos.informationStructuring.titlesTextRelevance.set(
        key,
        value === "relevant"
      );

      infos.informationStructuring.titlesTextRelevance = new Map(
        infos.informationStructuring.titlesTextRelevance
      );

      return infos;
    });
  };

  const contentInterventionValue =
    hasGoodContent !== undefined
      ? hasGoodContent
        ? "relevant"
        : "notRelevant"
      : undefined;

  const hierarchyInterventionValue =
    hasGoodHierarchy === undefined
      ? "auto"
      : hasGoodHierarchy
        ? "relevant"
        : "notRelevant";

  return (
    <React.Fragment>
      <FormControl component="fieldset" sx={{ width: "100%", mt: 2 }}>
        <Typography
          sx={{
            marginBottom: "0px",
            lineHeight: "1",
            fontSize: "1.1rem",
          }}
        >
          Le texte du titre est-il pertinent ?
        </Typography>
        <RadioGroup
          value={contentInterventionValue}
          onChange={(e) =>
            handleUpdateContentIntervention(e.target.value as any)
          }
        >
          <FormControlLabel
            sx={{
              marginBottom: "-12px",
              marginTop: "-2px",
            }}
            value={"relevant"}
            control={<Radio size="small" color={color} />}
            label="Pertinent"
          />
          <FormControlLabel
            value={"notRelevant"}
            control={<Radio size="small" color={color} />}
            label="Non pertinent"
          />
        </RadioGroup>
      </FormControl>

      <FormControl component="fieldset" sx={{ width: "100%", mt: 2 }}>
        <Typography
          sx={{
            marginBottom: "0px",
            lineHeight: "1",
            fontSize: "1.1rem",
          }}
        >
          La hierarchie du titre est-elle pertinente ?
        </Typography>
        <RadioGroup
          onChange={(e) =>
            handleUpdateHierarchyIntervention(e.target.value as any)
          }
          value={hierarchyInterventionValue}
        >
          <FormControlLabel
            sx={{
              marginBottom: "-12px",
              marginTop: "-2px",
            }}
            value={"auto"}
            control={<Radio size="small" color={color} />}
            label="Automatique"
          />
          <FormControlLabel
            sx={{
              marginBottom: "-12px",
            }}
            value={"relevant"}
            control={<Radio size="small" color={color} />}
            label="Pertinente"
          />
          <FormControlLabel
            value={"notRelevant"}
            control={<Radio size="small" color={color} />}
            label="Non pertinente"
          />
        </RadioGroup>
      </FormControl>
    </React.Fragment>
  );
};

export default HeadingOverlayForm;
