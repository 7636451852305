import React, { useCallback, useContext, useMemo } from "react";
import { useInterventions } from "providers/InterventionsProvider";
import { ReportContext } from "providers/Report";
import Box from "@mui/material/Box/Box";
import PictureAlternativeTextIntervention from "../interventions/pictures/PictureAlternativeTextIntervention";
import PictureTextDetectedIntervention from "../interventions/pictures/PictureTextDetectedIntervention";
import useScaner from "hooks/useScaner";
import RetributionButtonPopper from "../RetributionButtonPopper";
import { PictureScan, PictureUsefulness } from "@ca/report";

export interface PicturesRetributionProps {}

const PicturesRetribution: React.FC<PicturesRetributionProps> = () => {
  const { currentPageScan } = useScaner();

  const { pageInterventions, updateInfos } = useInterventions();
  const { pageReport } = useContext(ReportContext)!;

  const handleUpdateIntervention = useCallback(
    (scan: PictureScan, intervention: PictureUsefulness) => {
      updateInfos((infos) => {
        infos.picturesUsefulness.set(scan.imageHash, intervention);
        infos.picturesUsefulness = new Map(infos.picturesUsefulness);

        return infos;
      });
    },
    [updateInfos]
  );

  const isElementTooSmall = useCallback((el: HTMLElement) => {
    const rect = el.getBoundingClientRect();
    return rect.width < 80 || rect.height < 80;
  }, []);

  const picturesRetribution = useMemo(() => {
    if (!currentPageScan) return [];

    return currentPageScan.scan.pictures.map((pictureScan) => {
      const intervention = pageInterventions.picturesUsefulness.get(
        pictureScan.imageHash
      );

      const needIntervention =
        pageReport?.neededInterventions.some(
          (intervention) =>
            "xPath" in intervention &&
            intervention.xPath === pictureScan.xPath &&
            (intervention.type === "pictureUsefulness" ||
              intervention.type === "pictureAltTextRelevance")
        ) || false;

      const hasDetectedText =
        pictureScan.screenshotDetails.detectedText &&
        pictureScan.screenshotDetails.detectedText?.length > 0;

      return (
        <RetributionButtonPopper
          key={pictureScan.xPath}
          xPath={pictureScan.xPath}
          placement={(el) =>
            isElementTooSmall(el) ? "bottom" : "bottom-start"
          }
          offset={(el) => (isElementTooSmall(el) ? [0, 4] : [4, -36])}
        >
          <Box
            sx={{
              display: "flex",
              gap: "4px",
            }}
          >
            <PictureAlternativeTextIntervention
              pictureScan={pictureScan}
              needIntervention={needIntervention}
              intervention={intervention || null}
              onUpdateIntervention={handleUpdateIntervention}
            />
            {hasDetectedText && (
              <PictureTextDetectedIntervention
                pictureScan={pictureScan}
                intervention={intervention || null}
                onUpdateIntervention={handleUpdateIntervention}
              />
            )}
          </Box>
        </RetributionButtonPopper>
      );
    });
  }, [
    pageInterventions.picturesUsefulness,
    currentPageScan,
    handleUpdateIntervention,
    isElementTooSmall,
    pageReport?.neededInterventions,
  ]);

  return <React.Fragment>{picturesRetribution}</React.Fragment>;
};

export default PicturesRetribution;
