import { useContext } from "react";
import { CacheProvider } from "@emotion/react";
import React from "react";
import {
  CheckAccessFrameContext,
  DeviceMode,
} from "providers/CheckAccessFrame/CheckAccessFrame";
import { CARetributionActiveTopicsContext } from "providers/CARetributionActiveTopics";
import PicturesRetribution from "./FrameTopicRetribution/PicturesRetribution";
import ColorsRetribution from "./FrameTopicRetribution/ColorsRetribution";
import MandatoryElementsRetribution from "./FrameTopicRetribution/MandatoryElementsRetribution";
import useCurrentProject from "hooks/useCurrentProject";
import FramesRetribution from "./FrameTopicRetribution/FramesRetribution";
import { SIDEBAR_MAX_WIDTH } from "../sidebar/ScanReportSidebar";
import DeviceMockup from "./DeviceMockup";
import TopToolbar, { TOP_TOOLBAR_HEIGHT } from "./TopToolbar/TopToolbar";
import Box from "@mui/material/Box/Box";
import { useCurrentProjectPage } from "providers/CurrentFrameProjectPage/CurrentFrameProjectPage";

const API_URL = process.env.REACT_APP_CHECK_ACCESS_PROXY_URL!;

const computeScale = (
  deviceHeight: number,
  deviceWidth: number,
  heightMargin: number,
  widthMargin: number
) => {
  const height = window.innerHeight - heightMargin - TOP_TOOLBAR_HEIGHT;
  const width = window.innerWidth - widthMargin - SIDEBAR_MAX_WIDTH;
  const scale = Math.min(height / deviceHeight, width / deviceWidth);

  console.log(
    "heightScale: ",
    height / deviceHeight,
    " widthScale: ",
    width / deviceWidth
  );

  return scale;
};

const DEVICE_STYLE: Record<DeviceMode, () => React.CSSProperties> = {
  [DeviceMode.Laptop]: () => ({
    width: "1440px",
    height: "900px",

    marginTop: "15px",

    transform: `scale(${computeScale(900, 1440, 30, 30)})`,
    transformOrigin: "top",
    position: "absolute",
  }),
  [DeviceMode.Tablet]: () => ({
    width: "1024px",
    height: "768px",
    transformOrigin: "center 0px",
    marginTop: "37px",
    transform: `scale(${computeScale(768, 1024, 160, 0)})`,
  }),
  [DeviceMode.Phone]: () => {
    return {
      width: "360px",
      height: "780px",
      transformOrigin: "center 0px",
      marginTop: "20px",
      transform: `scale(${computeScale(780, 360, 100, 0)})`,
    };
  },
};

interface ScanReportRetributionProps {}

const ScanReportRetribution: React.FC<ScanReportRetributionProps> = () => {
  const { isTopicActivated, isTheOnlyTopicActivated } = useContext(
    CARetributionActiveTopicsContext
  )!;

  const { handleFrameLoad, deviceMode, shadowContainerStyleCache } = useContext(
    CheckAccessFrameContext
  )!;

  const currentProjectPage = useCurrentProjectPage();

  const currentProject = useCurrentProject();

  const frameUrl = `${API_URL}${currentProjectPage.path}?ca_loaded_domain=${currentProject.domain}`;

  return (
    <CacheProvider value={shadowContainerStyleCache}>
      <div className="flex flex-col w-full h-full">
        <TopToolbar />
        <Box
          sx={{
            backgroundColor: "primary.light",
            display: "flex",
            justifyContent: "center",
            maxHeight: `calc(100% - ${TOP_TOOLBAR_HEIGHT}px)`,
            maxWidth: `calc(100vw - ${SIDEBAR_MAX_WIDTH}px)`,
            flexGrow: 1,
          }}
        >
          <DeviceMockup />
          <iframe
            key={"website-preview-frame"}
            referrerPolicy="no-referrer"
            style={{
              ...DEVICE_STYLE[deviceMode]?.(),
              backgroundColor: "white",
            }}
            title="website-preview"
            onLoad={handleFrameLoad}
            src={frameUrl}
          />
          {isTopicActivated("images") && <PicturesRetribution />}
          {isTopicActivated("frames") && <FramesRetribution />}
          {isTheOnlyTopicActivated("colors") && <ColorsRetribution />}
          {isTheOnlyTopicActivated("mandatoryElements") && (
            <MandatoryElementsRetribution />
          )}
        </Box>
      </div>
    </CacheProvider>
  );
};

export default ScanReportRetribution;
